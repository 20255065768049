import React from "react"

import Layout from "src/components/common/Layout"
import SEO from "src/components/common/SEO"
import styled from "styled-components"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <StyledContainer>
      <>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </>
    </StyledContainer>
  </Layout>
)

export default NotFoundPage

const StyledContainer = styled.div`
  padding: 2rem;
  color: #fff;
`
